<template>
  <b-card class="full-height mb-0">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <skeletor
        width="32px"
        height="22px"
      />
      <skeletor
        width="32px"
        height="14px"
      />
    </div>
    <skeletor
      width="80%"
      height="22px"
      class="mb-50"
    />
    <skeletor
      width="65%"
      height="14px"
    />
    <div class="thin-divider my-1" />
    <div class="d-flex justify-content-end">
      <skeletor
        width="60%"
        height="22px"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  name: 'LeadReplyCardSkeleton',
  components: {
    BCard,
  },
}
</script>

<style scoped>

</style>
