<template>
  <div>
    <b-row class="mb-2">
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="mb-1 mb-sm-0"
      >
        <b-row>
          <b-col
            cols="12"
            lg="6"
            class="mb-1 mb-lg-0"
          >
            <v-select
              v-model="query.sortBy"
              :options="sortOptions"
              label="label"
              :clearable="false"
              :reduce="s => s.value"
              placeholder="Сортировка"
            />
          </b-col>
          <b-col
            cols="12"
            lg="6"
          >
            <v-select
              v-model="query.sortOrder"
              :options="[{value: 'asc', label: 'По возрастанию'}, {value: 'desc', label: 'По убыванию'}]"
              label="label"
              :reduce="s => s.value"
              placeholder="Порядок"
              :clearable="false"
            />
          </b-col>
        </b-row>

      </b-col>
      <b-col class="d-none d-lg-flex" />
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-input-group class="bg-transparent">
          <b-input-group-prepend is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-prepend>
          <b-form-input
            v-debounce:600ms="updateSearch"
            type="text"
            placeholder="Поиск..."
            class="bg-transparent"
          />
        </b-input-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        v-for="reply in replies"
        :key="reply.id"
        cols="12"
        xl="3"
        md="4"
        sm="6"
        style="margin-bottom: 2rem"
      >
        <lead-reply-card
          can-accept
          :reply="reply"
          @onAccept="reloadList"
        />
      </b-col>
    </b-row>

    <infinite-loading
      ref="infiniteLoader"
      @infinite="infiniteHandler"
    >
      <div slot="spinner">
        <b-row>
          <b-col
            v-for="reply in 4"
            :key="reply.id"
            cols="12"
            xl="3"
            md="4"
            sm="6"
            style="margin-bottom: 2rem"
          >
            <lead-reply-card-skeleton />
          </b-col>
        </b-row>
      </div>
      <div slot="no-more" />
      <div slot="no-results">
        <h3 class="mt-2">
          <span v-if="!replies.length && query.search">Ничего не найдено по запросу <b>"{{ query.search }}"</b></span>
          <span v-else-if="!replies.length">Пока нет откликов</span>
        </h3>
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import {
  BRow, BCol, BInputGroup, BFormInput, BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  LEAD_STATUS_ACTIVE,
  LEAD_STATUS_ACTIVE_LABEL,
  LEAD_STATUS_DONE,
  LEAD_STATUS_DONE_LABEL,
} from '../../shared/constants'
import LeadReplyCard from '../../components/leads/lead_page/teacher/LeadReplyCard.vue'
import LeadReplyCardSkeleton from '../../components/leads/lead_page/LeadReplyCardSkeleton.vue'

export default {
  name: 'LeadReplies',
  components: {
    LeadReplyCardSkeleton,
    LeadReplyCard,
    InfiniteLoading,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    vSelect,
  },
  data: () => ({
    replies: [],
    query: {
      limit: 12,
      sortBy: 'id',
      sortOrder: 'desc',
      search: '',
      status: null,
    },
    abortController: null,
  }),
  computed: {
    statuses() {
      return [
        {
          value: LEAD_STATUS_ACTIVE,
          label: LEAD_STATUS_ACTIVE_LABEL,
        },
        {
          value: LEAD_STATUS_DONE,
          label: LEAD_STATUS_DONE_LABEL,
        },
      ]
    },
    sortOptions() {
      return [
        {
          value: 'id',
          label: 'Номер отклика',
        },
        {
          value: 'lesson_price',
          label: 'Стоимость занятия',
        },
        {
          value: 'created',
          label: 'Дата создания',
        },
      ]
    },
  },
  watch: {
    query: {
      deep: true,
      handler() {
        this.reloadList()
      },
    },
  },
  methods: {
    reloadList() {
      this.replies = []
      this.$refs.infiniteLoader.stateChanger.reset()
    },
    updateSearch(value) {
      this.query.search = value ?? ''
    },
    infiniteHandler($state) {
      if (this.abortController) this.abortController.abort()
      this.abortController = new AbortController()
      const params = { ...this.query, offset: this.replies.length }

      this.$chatsHttp.get('/lead-replies', {
        signal: this.abortController.signal,
        params,
      }).then(async replies => {
        if (replies.length) {
          this.replies = [...this.replies, ...replies]
          if (replies.length < this.query.limit) $state.complete()
          else $state.loaded()
        } else {
          $state.complete()
        }
      }).catch(err => {
        if (err?.message !== 'canceled') $state.error()
      })
    },
  },
}
</script>

<style scoped lang="scss">

</style>
